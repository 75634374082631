import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {fetchDuplicateSubjectsBySsn} from "../../../../actions/subject_actions";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import SysSubjectMergeEditModal from "./sys_subject_merge_edit";

class SysSubjectMergeList extends Component {
    constructor(props){
        super(props);

        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {
        this.props.fetchDuplicateSubjectsBySsn();
    }

    onFinish(){
        this.props.fetchDuplicateSubjectsBySsn();
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/sys/dash/subject">
    //                 Subjects
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Duplicate Subjects
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {subjects} = this.props;

        if (!subjects){
            return <div>Loading...</div>
        }
        console.log(subjects);

        const columns = [
            {
                dataField: "socialSecurityNumber",
                text: "SSN",
                sort: true,
                filter: textFilter()
            },
            {
                dataField: "duplicateCount",
                text: "Duplicates",
                sort: true
            },
            {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            {row.duplicateSubjects && row.duplicateSubjects.length > 0 &&
                            <SysSubjectMergeEditModal mergedSubject={row.duplicateSubjects[0]}
                                                      ssn={row.socialSecurityNumber} onFinish={this.onFinish}/>
                            }
                        </Fragment>
                    );
                }
            }
        ];

        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                <h3>Duplicate Subjects</h3>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="socialSecurityNumber" data={subjects} columns={columns}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }
}

function mapStateToProps({subjects}){
    return {
        subjects
    }
}

export default connect(mapStateToProps, {fetchDuplicateSubjectsBySsn})(SysSubjectMergeList);