/**
 * Created by pshivaraman on 8/22/18.
 */

import React, {Component} from "react";
import BreadcrumbLink from "../../../components/bootstrap_addons/breadcrumb_link";
import {connect} from "react-redux";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class SysDashIndex extends Component {
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Dashboard
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div>System Dashboard Index</div>
            </div>
        )
    }
}

export default connect(null, null)(SysDashIndex);