import React, {Component} from "react";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {Card, Tab, Tabs} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import {connect} from "react-redux";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {fetchRemainingSignatures, fetchPurchaseHist} from "../../../../actions/signature_purchase_actions";
import Alert from "react-bootstrap/Alert";
import paginationFactory from "react-bootstrap-table2-paginator";
import {AddSignatures} from './add_signatures_modal'

class PurchaseList extends Component {
    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.state={

        }
    }

    handleDelete() {
        this.props.history.push("/sys/dash/");
    }

    componentDidMount() {
        this.getRemainingSignatures();
    }

    getRemainingSignatures = async () => {
        console.log("Fetching signatures");
        let sigUI = await fetchRemainingSignatures(
            (error) => {
                this.props.showErrorGrowl("Server Error", "The organization could not be found");
            }
        );
        console.log('Signature usage is: ', sigUI);
        this.setState({sigMap: sigUI.orgName2SigUsageMap});
    }

    getPurchaseHistory = async () => {
        let sigHist = await fetchPurchaseHist(
            (error) => {
                this.props.showErrorGrowl("Server Error", "The purchase history could not be found");
                console.log("History error: ", error);
            }
        );
        console.log('History: ', sigHist);
        this.setState({signatureHistory: sigHist});
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Signatures
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }


    render() {

        const {sigMap} = this.state;




        if (!sigMap) {
            return (
                <div>Loading...</div>
            )
        }
        let signatureUsageList = [];
        let sigUsageArr = Object.entries(sigMap);
        if (sigUsageArr && sigUsageArr.length > 0) {
            for (let [key, value] of sigUsageArr) {
                let tempObj = {"name":key, "signatures": value};
                signatureUsageList.push(tempObj);
            }
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <div className="float-right">
                    <ButtonToolbar>
                        <AddSignatures onComplete={this.getRemainingSignatures}/>
                    </ButtonToolbar>
                </div>
                <h3 className="page_title_left">Signature Details</h3>
                <br/>
                <Tabs defaultActiveKey='general' id='signature_purchase_overview+tab'>
                    <Tab eventKey='general' title={'Signature Overview'}>
                        <div className="sys_dash_page" header={'Overview'}>
                            <BootstrapTable boostrap4={true} hover striped bordered={true} keyField="name" data={signatureUsageList}
                                            columns={[
                                                { dataField: 'name', text: 'Organization name'},
                                                { dataField: 'signatures', text: 'Signatures Used this Month'}
                                            ]}
                                            pagination={paginationFactory({page: 1, sizePerPage: 10})}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        )
    }

}

function mapStateToProps({purchases}) {
    return {
        purchases
    }
}

export default connect(mapStateToProps, {showSuccessGrowl, showErrorGrowl})(PurchaseList)