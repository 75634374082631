import React, { Component, Fragment } from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { connect } from "react-redux";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal"
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { TemplateModel } from "./templateModel";
import { fetchAvailableTemplates, fetchOrgTemplates, fetchTemplates } from "../../../../actions/signature_actions";
import {
    addSigTemplate,
    fetchOrg,
    unassignManagingOrg,
    updateOrgForSigTemplates,
    updateSignatureTemplate
} from "../../../../actions/org_actions";

class SigTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availTemplates: [],
            signatureTemplates: [],
            orgId: this.props.id
        };
    }
    componentDidMount() {
        this.getTemplates();
        this.getsignatureTemplates();
    }
    setChosenTemplatesFromObj = () => {
        let { availTemplates, signatureTemplates } = this.state;
        let sigTemplates = [];
        for (let template of signatureTemplates) {
            sigTemplates.push(template.id);
        }
        let unassignTemplatelist = availTemplates.filter(objA => !sigTemplates.includes(objA.value));
        unassignTemplatelist = unassignTemplatelist.map(r => {
            return {
                name: r.label,
                id: r.value
            }
        })
        return unassignTemplatelist;
    }

    getTemplates = async () => {
        let availtemplates = await fetchAvailableTemplates(
            (error) => {
                console.log("Template fetch error", error);
                this.props.showErrorGrowl("Signature Templates Error", "Unable to fetch signature templates")
            }
        );
        this.setState({
            availTemplates: availtemplates.map(template => {
                return {
                    value: template.id,
                    label: template.name
                }
            })
        });

    }

    getsignatureTemplates = async () => {
        let template = await fetchOrgTemplates(this.props.id, (error) => {
            console.log("Template fetch error", error);
            this.props.showErrorGrowl("Signature Templates Error", "Unable to fetch signature templates")
        })
        console.log("template",template)
        this.setState({
            signatureTemplates: template && template.map(temp => {
                return {
                    id: temp.id,
                    name: temp.name, 
                    documentType: temp.documentType ? temp.documentType.id :null,
                    documentTypename: temp.documentType ? temp.documentType.type :null
                }
            }),
        });

    }

    handleAddSubmit = async (values) => {
        try {
            await addSigTemplate(this.state.orgId, values);
            this.props.showSuccessGrowl("Added Successfully", "Organization's signature templates added");
            await this.getsignatureTemplates();
        } catch (e) {
            console.log('Error is ', e);
            this.props.showErrorGrowl("Template Add", "Unexpected error occurred");
        }
    }

    handleEditSubmit = async (values) => {
        console.log('Values are ', values);
        try {
            let response = await updateSignatureTemplate(this.state.orgId, values);
            console.log('Response is ', response)
            this.props.showSuccessGrowl("Template Update", "Signature Template Updated successfully");
            await this.getsignatureTemplates();
        } catch (e) {
            console.log('Error is ', e);
            this.props.showErrorGrowl("Template Update", "Unexpected error occurred");
        }
    }

    handleDeleteTemplate = (template) => {
        let { signatureTemplates, orgId } = this.state;
        let _signatureTemplates = signatureTemplates.filter(objA => !template.id.includes(objA.id)); 
        let postData = { id: orgId, signatureTemplates: _signatureTemplates }
        this.props.updateOrgForSigTemplates(postData, orgId, () => {
            this.props.showSuccessGrowl("Deleted Successfully", "Organization's signature templates deleted");
            this.getsignatureTemplates();
        });

    }

    render() {
        const { signatureTemplates } = this.state;
        let unassignTemplates = this.setChosenTemplatesFromObj()
        return (
            <Fragment>
                <TemplateModel tempType={"New"} availTemplates={unassignTemplates} handleSubmit={this.handleAddSubmit} />
                <div class="react-bootstrap-table">
                    <table class="table table-striped table-hover">
                        <thead>
                            <th>Template</th>
                            <th>Document Type</th>
                            <th class="align-center">Action</th>
                        </thead>
                        <tbody>
                            {
                                signatureTemplates && signatureTemplates.map((row, index) => (
                                    <tr>
                                        <td>{row.name}</td>
                                        <td>{row.documentTypename && row.documentTypename }</td>
                                        <td class="align-center">
                                            <ButtonToolbar style={{ display: "inline-flex" }}>
                                                <TemplateModel tempType={"Edit"} availTemplates={[row]}
                                                    selectedDoctype={row.documentType} template={row}
                                                    handleSubmit={this.handleEditSubmit}
                                                /> 
                                                <DeleteConfirmationModal deleteEntity="Template" buttonType={'link'}
                                                    handleDeleteCallback={() => this.handleDeleteTemplate(row)}
                                                    jsxButtonType={<span title={"Delete Template"}
                                                        aria-hidden="true" style={{ padding: 10 }} aria-label="Remove Template">
                                                        <i class="fa fa-trash"></i></span>}
                                                />
                                            </ButtonToolbar>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}



export default connect(null, {
    updateOrgForSigTemplates,
    showSuccessGrowl,
    showErrorGrowl
})(SigTemplateList);