import React, {Component, Fragment} from "react";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {addSignatures} from '../../../../actions/signature_actions';
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form} from "react-bootstrap";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropTypes from "prop-types";


class AddSignaturesModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false
        }
    }

    open() {
        this.setState({
            showModal: true
        });
    }

    onSubmit = async (values) => {
        console.log('Adding signatures now');
        try {
            await addSignatures(values.signaturesBought);
            this.props.showSuccessGrowl("Signatures bought", "Signatures have been bought and organization administrators will be notified.");
            console.log('Signatures Added');
            this.close();
            this.props.onComplete();
        } catch(error) {
            console.log('Error is ', error);
            this.props.showErrorGrowl("Unexpected error when adding signatures");
        }
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit, error} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>Add Signatures</Button>
                <Modal show={showModal} onHide={this.close}>
                    <Formik
                        initialValues={{
                            "signaturesBought": "",
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                signaturesBought: Yup.number().test(
                                    'Non-zero-check',
                                    'Enter a postive number for signatures bought',
                                    function (value) {
                                        return value > 0;
                                    }
                                ).required('Enter signatures bought').typeError('Please enter a positive number greater than 0'),
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Add Signatures Form</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'signaturesBought'}>
                                                <Form.Label>Enter signatures bought</Form.Label>
                                                <Form.Control required type="text" name={'signaturesBought'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter the number of signatures bought'}
                                                              isInvalid={touched.signaturesBought && !!errors.signaturesBought}
                                                              value={values.signaturesBought}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.signaturesBought}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                                            <Button variant="danger" onClick={this.close}>Cancel</Button>
                                        </ButtonToolbar>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const AddSignatures = connect(null, {showSuccessGrowl, showErrorGrowl})(AddSignaturesModal);

AddSignatures.propTypes = {
    onComplete: PropTypes.func.isRequired
}
