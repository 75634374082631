import React, {Component, Fragment} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class CloseBrowserScreen extends Component {
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <Breadcrumb.Item active>
    //                 Close Window
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render(){
        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                <h3>Completed</h3>
                <p>You may now close this window...</p>
            </Fragment>
        )
    }
}

export default CloseBrowserScreen;