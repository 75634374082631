import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {TOKEN_KEY, SECOND_FACTOR, ROLES, ROLES_OBJECT} from '../../actions/auth_actions';
import SessionTimeoutModal from "../../components/session_timeout_modal";
import {CUR_ORG} from "../../actions/session_constants";

// export function ConditionalDisplay(ComposedComponent) {
//     if (!sessionStorage.getItem(ROLES).includes('PRG_MGR')){
//         return <ComposedComponent/>
//     }
// }

export function ConditionalDisplay(ComposedComponent) {
    if (!isReadOnlyRole()){
        return ComposedComponent;
    }
}

export function isReadOnlyRole(){
    return isRoleWithOrg('PRG_MGR') || isRoleWithOrg('HR_MGR') || isRoleWithOrg('CONTRACT_PRG_MGR');
}

function isRoleWithOrg(role){
    let rolesObj = JSON.parse(sessionStorage.getItem(ROLES));
    let orgId = sessionStorage.getItem(CUR_ORG);
    if (rolesObj){
        for (let eachRole of rolesObj){
            if (eachRole.role === role && eachRole.owningEntity === 'ORG' &&
                eachRole.owningEntityId === orgId){
                return true;
            }
        }
    }
    return false;
}

function isNoOtherRoleExcept(roles){
    let rolesObj = JSON.parse(sessionStorage.getItem(ROLES));
    let orgId = sessionStorage.getItem(CUR_ORG);
    if (rolesObj){
        for (let eachRole of rolesObj){
            if (!roles.includes(eachRole.role) && eachRole.owningEntity === 'ORG' &&
                eachRole.owningEntityId === orgId){
                return false;
            }
        }
    }
    return true;
}

export function isContractOnlyRole(){
    return ((isRoleWithOrg('CONTRACT_ADMIN') || isRoleWithOrg('CONTRACT_PRG_MGR')) &&
        isNoOtherRoleExcept(['CONTRACT_ADMIN', 'CONTRACT_PRG_MGR']));
}

export function isHROnlyRole(){
    return (isRoleWithOrg('HR_MGR') &&
        isNoOtherRoleExcept(['HR_MGR']));
}

export function isHRRole(){
    return isRoleWithOrg('HR_MGR');
}

export function shouldDisplayOrgDash(){
    let rolesObj = JSON.parse(sessionStorage.getItem(ROLES))
    console.log('Roles are ', rolesObj)
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgFacilities(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgDocs(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgContracts(){
    return !isHROnlyRole();
}

export function shouldDisplayOrgTasks(){
    return !isContractOnlyRole();
}

export function shouldDisplayOrgScreening(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgAssets(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayDocOrgControls(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgVisits(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgSubjects(){
    return !isContractOnlyRole();
}

export function shouldDisplayOrgReport(){
    return !isContractOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgUsers(){
    return !isContractOnlyRole() && !isReadOnlyRole() && !isHROnlyRole();
}

export function shouldDisplayOrgServiceCenter(){
    return !isContractOnlyRole();
}

export function shouldDisplayOrgSettings(){
    return !isContractOnlyRole() && !isHROnlyRole();
}
