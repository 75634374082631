import React, {Component, Fragment} from "react";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {connect} from "react-redux";
import {fetchTask, fetchTaskChildren, resetTaskEmailStatus} from "../../../../actions/task_actions";
import {showSuccessGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {Link} from "react-router-dom";
import {DateUtils} from "../../../../actions/common_utils";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {isReadOnlyRole} from "../../../util/shouldDisplay";

class TaskDetail extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.props.fetchTask(this.props.match.params.id);
        this.props.fetchTaskChildren(this.props.match.params.id);
    }

    getColumns() {
        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        }

        return [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'dateCompleted',
            text: 'Date Completed',
            formatter: (cell, row) => cell ? DateUtils.getDateTimeString(cell) : "Not Completed",
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'emailStatus',
            text: 'Email Status',
            formatter: (cell, row) => cell && cell.displayName,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        <ButtonToolbar>
                            {!row.dateCompleted && !isReadOnlyRole() &&
                            <Button variant="outline-info"
                                    onClick={() => this.props.resetTaskEmailStatus(row.issueId, () => {
                                        this.props.fetchTask(this.props.match.params.id);
                                        this.props.fetchTaskChildren(this.props.match.params.id);
                                        this.props.showSuccessGrowl("Email Status Changed", `The email will be resent shortly for ${row.personFirstName} ${row.personLastName}.`);
                                    })}>
                                <i className="fa fa-repeat"/> Resend Email
                            </Button>
                            }
                        </ButtonToolbar>
                    </Fragment>
                )
            }
        }];
    }

    getColumnsForEmailValidation() {
        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        }

        return [{
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => {cell},
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'dateCompleted',
            text: 'Date Completed',
            formatter: (cell, row) => cell ? DateUtils.getDateTimeString(cell) : "Not Completed",
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'emailValidationStatus',
            text: 'Validation Status',
            formatter: (cell, row) => cell && cell.displayName,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/sys/dash/task">
    //                 Tasks
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 {this.props.task.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    renderSubjectIssue(task) {
        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}

                <h3>{task.name}</h3>
                <p>{task.description}</p>
                <p>Completed
                    On: {task.dateCompleted ? DateUtils.getDateString(task.dateCompleted) : "Not Completed"}</p>
                <p>Email Status: {task.emailStatus ? task.emailStatus.displayName : "None"}</p>
                <ButtonToolbar>
                    {task.dateCompleted && !isReadOnlyRole() &&
                    <Button variant="outline-info"
                            onClick={() => this.props.resetTaskEmailStatus(this.props.match.params.id, () => {
                                this.props.fetchTask(this.props.match.params.id);
                                this.props.fetchTaskChildren(this.props.match.params.id);
                                this.props.showSuccessGrowl("Email Status Changed", "The email will be resent shortly");
                            })}>
                        <i className="fa fa-repeat"/> Resend Email
                    </Button>
                    }
                </ButtonToolbar>
            </Fragment>
        )
    }

    renderEmailIssue(task, tasks){
        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}

                <h3>{task.name}</h3>
                <p>{task.description}</p>
                <p>Completed
                    On: {task.dateCompleted ? DateUtils.getDateString(task.dateCompleted) : "Not Completed"}</p>

                <h6>Percent Completed</h6>
                <ProgressBar now={task.percentComplete}/>
                <br/>
                <Alert key={'progressBarAlertKey'} variant={'warning'}>
                    Progress bar status may be out of sync by a few minutes as it is updated by a timer!
                </Alert>

                <h5>Subjects</h5>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={tasks ? tasks : []}
                                columns={this.getColumnsForEmailValidation()} filter={filterFactory()}/>
            </Fragment>
        )
    }

    render() {
        const {task, tasks} = this.props;

        if (!task) {
            return <div>Loading...</div>;
        }

        // Handle Subject Issues
        if (task.entityType && task.entityType.id === "SUBJECT") {
            return this.renderSubjectIssue(task);
        }

        // Handle Email Issues
        if (task.issueType.id === "SUBJECT_EMAIL_VALIDATE"){
            return this.renderEmailIssue(task, tasks);
        }

        let childTasks = tasks;

        if (childTasks == null) {
            childTasks = [];
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <h3>{task.name}</h3>
                <p>{task.description}</p>

                <h6>Percent Completed</h6>
                <ProgressBar now={task.percentComplete}/>
                <br/>
                <Alert key={'progressBarAlertKey'} variant={'warning'}>
                    Progress bar status may be out of sync by a few minutes as it is updated by a timer!
                </Alert>

                <h5>Subjects</h5>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={childTasks}
                                columns={this.getColumns()} filter={filterFactory()}/>
            </div>
        );
    }
}

function mapStateToProps({task, tasks}) {
    return {
        task,
        tasks
    }
}

export default connect(mapStateToProps, {fetchTask, fetchTaskChildren, showSuccessGrowl, resetTaskEmailStatus})(TaskDetail);