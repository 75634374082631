/**
 * Created by pshivaraman on 4/27/18.
 */

import React, {Component, Fragment} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {
    fetchTaskByHashcode,
    fetchTaskByHashcodeForSecondFactor,
    uploadDocumentForTask
} from "../../actions/task_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import {TaskEmailDocUpload} from './email_file_upload';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Card, Form, Modal} from "react-bootstrap";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import {ISSUE_TO_UPLOAD} from "../../actions/session_constants";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";
import {downloadDocumentForSelfReporting} from "../../actions/doc_actions";
import {Link} from "react-router-dom";
import {setOrg} from "../../actions/org_actions";
import {reportingSecondFactor} from "../../actions/subject_actions";
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../actions/auth_actions";

class TaskEmailForwardComp extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        console.log('Called task email Upload');
        this.getIssueAndSetDocTypes();
    }

    getIssueAndSetDocTypes = async () => {
        if (this.props.match.params.emailCode) {
            try {
                const emailSecondFactorReq = await fetchTaskByHashcodeForSecondFactor(this.props.match.params.emailCode);
                this.props.reportingSecondFactor(emailSecondFactorReq).then((response) => {
                    // email is valid.. if the device is recognized then go to the reporting page
                    if (response){
                        sessionStorage.setItem(REPORTING_PASS_CODE, emailSecondFactorReq.passCode);
                        sessionStorage.setItem(VERIFIED_EMAIL, emailSecondFactorReq.emailAddress);
                        this.props.history.push("/reporting/subject/taskList");
                    } else {
                        this.props.showErrorGrowl("Pass code is not valid.")
                    }
                    console.log(response);
                }).catch((error) => {
                    this.props.showErrorGrowl("Unexpected error please contact system administrator.");
                    console.log(error);
                });

            } catch (error) {
                // Thats ok just redirect them to login
                this.props.history.push("/reporting/subject");
            }
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <Breadcrumb.Item active>
    //                 Document Uploader
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }


    render() {
        return <div>Redirecting...</div>
    }
}

export const TaskEmailForward = connect(null, {showSuccessGrowl, showErrorGrowl, reportingSecondFactor})(TaskEmailForwardComp);