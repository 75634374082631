/**
 * Created by shiva on 12/30/19.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {confirmSubjectChanges, fetchTaskByHashcode} from "../../actions/task_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {SUCCESS} from "../../actions/action_constants";
import {Card, Form} from "react-bootstrap";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {SubjectForeignVotingDetails} from "../org/dash/subject/details/subject_foreign_voting_details";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import {Formik} from "formik";
import * as Yup from "yup";
import {SubjectForeignTravelDetails} from "../org/dash/subject/details/subject_foreign_travel_details";
import {SubjectArrestDetails} from "../org/dash/subject/details/subject_arrest_details";
import {SubjectMediaContactDetails} from "../org/dash/subject/details/subject_media_contact_details";
import {SubjectForeignPropertyDetails} from "../org/dash/subject/details/subject_foreign_property_details";
import {SubjectForeignBankDetails} from "../org/dash/subject/details/subject_foreign_bank_details";
import {SubjectForeignCitizenshipDetails} from "../org/dash/subject/details/subject_foreign_citizenship_details";
import {SubjectForeignIdCardDetails} from "../org/dash/subject/details/subject_foreign_id_card_details";
import {SubjectForeignAdoptionDetails} from "../org/dash/subject/details/subject_foreign_adoption_details";
import {SubjectFinancialIssueAnomaliesDetails} from "../org/dash/subject/details/subject_financial_anomalies_details";
import {SubjectAlcoholDrugTreatmentDetails} from "../org/dash/subject/details/subject_alcohol_drug_treatment_details";
import {SubjectCohabitantDetails} from "../org/dash/subject/details/subject_cohabitant_details";
import {SubjectForeignIntelligenceContactDetails} from "../org/dash/subject/details/subject_foreign_intelligence_contact_details";
import {SubjectForeignContactListDetails} from "../org/dash/subject/details/subject_foreign_contact_ques_details";
import CollapsibleCard from "../../components/bootstrap_addons/collapsible-card";
import {SubjectAddressChangeDetails} from "../reporting/subject/personalInfo/subject_address_change";
import {SubjectMaritalStatusChange} from "../reporting/subject/personalInfo/marital_status_change";
import {SubjectForeignBusinessInvolvementDetail} from "../reporting/subject/personalInfo/review_foreign_business_involvement";
import {SubjectBlackmailDetails} from "../reporting/subject/personalInfo/black_mail_details";

class TaskEmailConfirmSubjectChanges extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    loadIssue = async () => {
        if (this.props.match.params.emailCode) {
            const issue = await fetchTaskByHashcode(this.props.match.params.emailCode);
            this.setState({
                issue
            })
        }
    }

    componentDidMount() {
        this.loadIssue().then(()=>console.log('Task loaded from backend'));
    }

    onSubmit = () => {
        // Call the backend with the hash key
        // if all goes well then display a message saying changes confirmed
        // if not an error and asking them to contact sys admin
        this.props.confirmSubjectChanges(this.props.match.params.emailCode, this.props.match.params.changeType, (response) => {
            if (SUCCESS === response){
                this.props.showSuccessGrowl("Self-reporting confirmed!");
                this.props.history.push("/misc/closeBrowser");
            } else {
                this.props.showErrorGrowl("Cannot confirm your changes. Please contact your security officer", errorMsg);
            }
        });
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <Breadcrumb.Item active>
    //                 Confirm Changes
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {issue} = this.state;

        if (!issue) {
            return <div>Loading...</div>;
        }

        console.log('Issue reported is ', issue);
        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                <Formik
                    initialValues={{
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                        })
                    }
                >
                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card>
                                <Card.Header>Reported changes to confirm</Card.Header>
                                <Card.Body>
                                    {(issue.changeType === 'FOREIGN_ELECTION_VOTING') &&
                                    <SubjectForeignVotingDetails foreignVotings={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_TRAVEL') &&
                                    <SubjectForeignTravelDetails foreignTravels={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'ARRESTS') &&
                                    <SubjectArrestDetails arrests={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'BLACK_MAIL_OR_OTHER_THREATS') &&
                                    <SubjectBlackmailDetails blackmails={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'MEDIA_CONTACT') &&
                                    <SubjectMediaContactDetails mediaContacts={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_PROPERTY_OWNERSHIP') &&
                                    <SubjectForeignPropertyDetails foreignProperties={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_BANK_ACCOUNT') &&
                                    <SubjectForeignBankDetails foreignBankAccts={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_CITIZENSHIP') &&
                                    <SubjectForeignCitizenshipDetails foreignCitizenships={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_PASSPORT_ID_POSSESSION') &&
                                    <SubjectForeignIdCardDetails foreignIdCards={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'ADOPTION_OF_NON_US_CHILDREN') &&
                                    <SubjectForeignAdoptionDetails foreignChildAdoptions={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FINANCIAL_ISSUES_AND_ANOMALIES') &&
                                    <SubjectFinancialIssueAnomaliesDetails financialIssuesAndAnomalies={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'ALCOHOL_DRUG_TREATMENT') &&
                                    <SubjectAlcoholDrugTreatmentDetails alcoholDrugTreatmentReportables={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'MEDIA_CONTACT') &&
                                    <SubjectMediaContactDetails mediaContacts={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'COHABITANT') &&
                                    <SubjectCohabitantDetails cohabitants={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'PERSONNEL_INFO_ADDRESS') &&
                                    <SubjectAddressChangeDetails addressChanges={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'PERSONNEL_INFO_MARITAL_STATUS') &&
                                    <SubjectMaritalStatusChange maritalChanges={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_INTELLIGENCE_ENTITY_CONTACT') &&
                                    <SubjectForeignIntelligenceContactDetails foreignIntelligenceEntities={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_BUSINESS_INVOLVEMENT') &&
                                    <SubjectForeignBusinessInvolvementDetail foreignBusInvolvements={[issue.reportableActivity]}/>
                                    }
                                    {(issue.changeType === 'FOREIGN_CONTACTS_QUESTIONNAIRE') &&
                                    <SubjectForeignContactListDetails contactReportables={[issue.reportableActivity]} travelReportables={[]}/>
                                    }
                                    {/*Add Foreign Contacts*/}
                                    <div className={"pull-right"}>
                                        <ButtonToolbar>
                                            <Button variant="primary" disabled={isSubmitting} type="submit">Confirm</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Form>
                        )}
                </Formik>
            </Fragment>
        )

    }
}

export default connect(null, {confirmSubjectChanges, showSuccessGrowl, showErrorGrowl})(TaskEmailConfirmSubjectChanges);