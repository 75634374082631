import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {sendContactEmail} from "../../actions/task_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import Card from "react-bootstrap/Card";
import {FieldRBInput, FieldRBSelect, FieldRBTextArea} from "../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {Formik} from 'formik';
import * as Yup from "yup";
import {CommonUtils} from "../../actions/common_utils";

class ContactUsFormComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit = (values) => {
        this.props.sendContactEmail(values).then(() => {
            this.props.showSuccessGrowl("Your information has been submitted. One of our support staff will get back to you");
            this.props.history.push("/misc/closeBrowser");
        }).catch((error) => {
            console.log('Error is ', error);
            this.props.showErrorGrowl('Unable to submit your contact information');
        });
    }


    render() {
        const {handleSubmit} = this.props;

        return (

            <div className="container-fluid reporting_bg">
                <div className="container">

                    <div className="heading_container">
                        <h3>Get in Touch</h3>
                    </div>

                    <div className="contact_tile">
                        <h3></h3>

                        <p>Get in touch with us using the contact form below and we will be happy to assist you.</p>
                    </div>
                    <Fragment>

                        <div className="col-lg-12 row m-0 contact_form">
                            <div className="col-lg-6">
                                <Formik
                                    initialValues={{
                                        fullName: '',
                                        email: '',
                                        subject: '',
                                        description: '',
                                        hearaboutus: ''
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={this.onSubmit}
                                    validationSchema={
                                        Yup.object().shape({
                                            fullName: Yup.string().required('Please enter your name'),
                                            email: Yup.string().email("Please enter a valid email address"),
                                            subject: Yup.string().required('Please enter a subject for your query'),
                                            hearaboutus: Yup.string().required('Please select an option'),
                                        })
                                    }
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                      }) => (
                                        <form onSubmit={handleSubmit}>

                                            <Form.Group as={Col} md="12" controlId={'fullName'}>
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control required type="text" name={'fullName'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Full Name'}
                                                              isInvalid={touched.fullName && !!errors.fullName}
                                                              value={values.fullName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fullName && touched.fullName && errors.fullName}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId={'email'}>
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control required type="text" name={'email'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Email Address'}
                                                              isInvalid={touched.email && !!errors.email}
                                                              value={values.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email && touched.email && errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Hear about us</Form.Label>
                                                <Form.Control required as="select" name={'hearaboutus'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'How did you hear about us'}
                                                              isInvalid={touched.hearaboutus && !!errors.hearaboutus}
                                                              value={values.hearaboutus}
                                                >
                                                    {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(['','Google / Other Search Engines','Referral / From a friend', 'LinkedIn', 'Twitter', 'Event / Conference', 'Other']))}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.hearaboutus}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId={'subject'}>
                                                <Form.Label>Subject</Form.Label>
                                                <Form.Control required type="text" name={'subject'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Please enter a Subject for your query'}
                                                              isInvalid={touched.subject && !!errors.subject}
                                                              value={values.subject}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.subject && touched.subject && errors.subject}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId={'description'}>
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control required as="textarea" name={'description'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter Message'}
                                                              isInvalid={touched.description && !!errors.description}
                                                              value={values.description}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.description && touched.description && errors.description}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <div className="btn-sec pull-right pb-0">
                                                <Button disabled={isSubmitting} className="btn-red"
                                                        type="submit">Submit</Button>
                                            </div>


                                        </form>
                                    )}
                                </Formik>
                            </div>

                            <div className="col-lg-6 row">
                                <div className="col-lg-6">
                                    <h5 className="color_red">Address</h5>
                                    <p>1889 Preston White Dr, <br></br>Suite 100, Reston, <br></br>VA 20191</p>
                                    <br></br>

                                    <h5 className="color_red">Business Hours</h5>
                                    <p>Mon - Fri: 8:00 - 17:00 <br></br> Sat - Sun: Closed</p>
                                </div>

                                <div className="col-lg-6">
                                    <h5 className="color_red">Contact</h5>
                                    <p className="mailto"><i className="fa fa-envelope"></i> <a
                                        href="mailto:support@tru-vetting.com">support@tru-vetting.com</a></p>
                                </div>

                                <div className="map_location">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.683798943119!2d-77.33024267049075!3d38.941277932326074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b649cba7fff2e9%3A0x766eb1574dd615d0!2s1889%20Preston%20White%20Dr%2C%20Reston%2C%20VA%2020191%2C%20USA!5e1!3m2!1sen!2sin!4v1685016519307!5m2!1sen!2sin"
                                        width="100%" height="260" style={{border: 0}} allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>

                    </Fragment>
                </div>
            </div>


        )
    }
}

export default connect(null, {sendContactEmail, showSuccessGrowl, showErrorGrowl})(ContactUsFormComponent);