/**
 * Created by pshivaraman on 4/27/18.
 */

import React, {Component, Fragment} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {
    fetchTaskByHashcode,
    fetchTaskByHashcodeForSecondFactor,
    uploadDocumentForTask
} from "../../actions/task_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import {TaskEmailDocUpload} from './email_file_upload';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Card, Form, Modal} from "react-bootstrap";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import {ISSUE_TO_UPLOAD} from "../../actions/session_constants";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";
import {downloadDocumentForSelfReporting} from "../../actions/doc_actions";
import {Link} from "react-router-dom";
import {setOrg} from "../../actions/org_actions";
import {reportingSecondFactor} from "../../actions/subject_actions";
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../actions/auth_actions";
import { FormikFileuploadComp } from "../../components/formik/formik_fileupload";

class TaskEmailUpload extends Component {
    constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);

        this.state = {
            showOther: true,
            docTypes: [],
            docsUploaded: []
        }
    }

    componentDidMount() {
        console.log('Called task email Upload');
        this.getIssueAndSetDocTypes();
    }

    getIssueAndSetDocTypes = async () => {
        if (this.props.match.params.emailCode) {
            try {
                const issue = await fetchTaskByHashcode(this.props.match.params.emailCode);
                if (issue.requiredDocs) {
                    let docTypes = [];
                    let docsUploaded = [];
                    for (let reqDoc of issue.requiredDocs) { 
                        docTypes.push(reqDoc.id);
                        docsUploaded.push({ name: null, docType: reqDoc.id });
                    }
                    console.log('Required docs got are ', docTypes);
                    this.setState({
                        docTypes: docTypes,
                        docsUploaded: docsUploaded,
                        issue
                    });
                }
            } catch (error) {
                console.log('Error is ', error)
                if (error.response && error.response.status === 404) {
                    this.props.showErrorGrowl("The task has already been completed")
                    this.props.history.push("/misc/closeBrowser");
                } else {
                    this.props.showErrorGrowl("Server Error", "A server error occurred!");
                    console.log('Error is ', error);
                }
            }
        }
    }

    onFileChange(files) {
        if (files[0]) {
            this.props.change("filename", files[0].name);
            this.props.change("filetype", files[0].type);
            this.props.change("previewUrl", files[0].preview);
        }
    }

    onTypeChange(event) {
        if (event.target.value === "OTHER") {
            this.setState({
                showOther: true
            });
        } else {
            this.setState({
                showOther: false
            });
        }
    }

    onSubmit = async (values) => {
        const {docsUploaded} = this.state;

        try {
            await uploadDocumentForTask(this.props.match.params.emailCode, docsUploaded);
            this.props.showSuccessGrowl("Document(s) Uploaded Successfully!");
            this.close();
        } catch (error) {
            let errorMsg = error;
            if (error && error.response && error.response.data){
                errorMsg = error.response.data;
            }
            this.props.showErrorGrowl("Error uploading document(s)", errorMsg);
        }
    }

    close = () => {
        let hashKey = sessionStorage.getItem(ISSUE_TO_UPLOAD);
        if (hashKey){
            sessionStorage.setItem(ISSUE_TO_UPLOAD, null);
            this.props.history.push("/reporting/subject/taskList");
        } else {
            this.props.history.push("/misc/closeBrowser");
        }
    }
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <Breadcrumb.Item active>
    //                 Document Uploader
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    onUpload = (document, docType) => {
        let {docsUploaded, docTypes} = this.state;
        const _docsUploaded = docsUploaded.map(doc =>
            doc.docType === docType ? document : doc
        );
        const index = docTypes.indexOf(docType);
        if (index > -1) {
            docTypes.splice(index, 1);
        }
        console.log(docsUploaded);

        this.setState({
            docTypes: docTypes,
            docsUploaded: _docsUploaded
        })
    }

    removeDocument = (file) => {
        let {docsUploaded, docTypes} = this.state;
        const _docsUploaded = docsUploaded.map(doc =>
            (doc.name === file.name && doc.docType === file.docType) ? { name: null, docType: file.docType } : doc
        );
        docTypes.push(file.docType);
        this.setState({
            docTypes: docTypes,
            docsUploaded: _docsUploaded
        })
    }

    handleCancel = () => {
        window.history.back();
    }

    getAwsKey = (row) => {
        console.log('Row is ', row);
        if (row.latestRevision){
            return row.latestRevision.awsS3ObjectKey;
        } else {
            return null;
        }
    }
    getInstructionDocColumns = () => {

        return [{
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => cell,
            sort: true,
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar className="p-0" style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocumentForSelfReporting(this.getAwsKey(row), this.props.match.params.emailCode)}>
                        <span aria-hidden="true" style={{paddingRight: 10}} title={"Download Document"} aria-label="Download Document">
                            <i className="fa fa-circle-down"></i>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        }]
    }

    makeLink = (text, url) => {
        return (
            <a href={url} onClick={() => {
                console.log('Clicked');
            }}>{text}</a>
        )
    };

    render() {
        const {handleSubmit} = this.props;
        const {docTypes, docsUploaded, issue} = this.state;

        const columns = [{
            dataField: 'name',
            text: 'File Name',
        }, {
            dataField: 'docType',
            text: 'Document Type',
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        <Button className="close" onClick={() => this.removeDocument(row)}>
                            <span aria-hidden="true" title={"Remove Document"} style={{padding: 10}} aria-label="Remove Document">
                            <i className="fa fa-times-circle" style={{color: "black"}}></i>
                            </span>
                        </Button>
                    </Fragment>
                )
            }
        }];

        if (!issue){
            return <div>Loading...</div>
        }
        console.log('Issue is ', issue)
        return (
            <div className="reporting_bg">
            <Fragment>
            <div className="reporting_screen1 container">
                        <div className="heading_container">
                            <h3>Upload Task Document</h3>
                        </div>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Modal.Body>
                        {issue.instructionDocs &&
                        <Card>
                            <Card.Header>Instruction Documents</Card.Header>
                            <Card.Body className="custom_table_h">
                                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="name"
                                                data={issue.instructionDocs}
                                                columns={this.getInstructionDocColumns()}/>
                            </Card.Body>
                        </Card>
                        }
                        {issue.signatureDocumentResponse &&
                        <Card>
                            <Card.Header>Signature Link</Card.Header>
                            <Card.Body>
                                <p>Please click on the link to complete your task: {this.makeLink('SignatureURL', issue.signatureDocumentResponse.recipients[0].sign_url)}</p>
                            </Card.Body>
                        </Card>
                        }
                        {!issue.signatureDocumentResponse &&
                        <Card>
                            <Card.Header>Document Uploader</Card.Header>
                            <Card.Body className="custom_table_h">
                            <div class="react-bootstrap-table">
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th tabindex="0">File Name</th>
                                            <th tabindex="1">Document Type</th>
                                            <th tabindex="2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.docsUploaded && this.state.docsUploaded.length > 0 && this.state.docsUploaded.map((row, index) => (
                                            <tr className={row.name && "uploadedDoc"}>
                                                <td>{row.name}</td>
                                                <td>{row.docType}</td>
                                                <td style={{display:"flex"}}>
                                                    <Fragment>
                                                        {row.name ? (
                                                            <Fragment>
                                                                {/* <Button variant={'link'} className="close" onClick={() => downloadDocumentForSelfReporting(this.getAwsKey(row), this.props.match.params.emailCode)}>
                                                                    <span aria-hidden="true" style={{ paddingRight: 10 }} title={"Download Document"} aria-label="Download Document">
                                                                        <i className="fa fa-circle-down"></i>
                                                                    </span>
                                                                </Button> */}
                                                                <Button variant={'link'} className="close" onClick={() => this.removeDocument(row)}>
                                                                    <span aria-hidden="true" title={"Remove Document"} style={{ padding: 10 }} aria-label="Remove Document">
                                                                        <i className="fa fa-times-circle" style={{ color: "black" }}></i>
                                                                    </span>
                                                                </Button>
                                                            </Fragment>) :
                                                            <Button variant={'link'} className="close">
                                                                <FormikFileuploadComp onUploadnew={this.onUpload} docType={row.docType} />
                                                            </Button>
                                                        }
                                                    </Fragment>
                                                </td>
                                            </tr>
                                        ))} 
                                    </tbody>
                                </table>
                            </div>
                            </Card.Body>
                        </Card>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {!issue.signatureDocumentResponse &&
                        <div className={"pull-right1"}>
                            <ButtonToolbar> 
                                <Button variant="primary" type="submit" disabled={docTypes.length != 0}>Submit</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"}
                                                         deleteEntity="Upload Document"
                                                         handleDeleteCallback={this.handleCancel}/>
                            </ButtonToolbar>
                        </div>
                        }
                    </Modal.Footer>
                </Form>
              </div>
            </Fragment>
            </div>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.file || values.file.size < 1) {
        errors.file = "You must upload a file!";
    }

    return errors;
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'TaskEmailUploadForm',
    enableReinitialize: true,
    initialValues: {
        document: {type: 'OTHER'}
    },
})(
    connect(null, {showSuccessGrowl, showErrorGrowl, reportingSecondFactor})(TaskEmailUpload)
);