/**
 * Created by pshivaraman on 8/29/18.
 */

import React from "react";
import {fetchSysOrgs} from "../../../../actions/org_actions";
import {connect} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {Link} from "react-router-dom";
import OrgNew from "../../../../components/org/org_new";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Card } from "react-bootstrap";

class SysOrgList extends React.Component {
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Organizations
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    componentDidMount() {
        this.props.fetchSysOrgs();
    }

    render() {
        const {orgs} = this.props;

        const columns = [
            {
                dataField: "org.name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/sys/dash/org/${row.org.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "org.pocName",
                text: "POC Name"
            },
            {
                dataField: "org.pocPhone",
                text: "POC Phone #"
            }
        ];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                
                
                <Card>
                    <Card.Header>
                        <Card.Title>
                        Organizations
                        </Card.Title>

                        <div className="pull-right">
                    <OrgNew onCreate={() => this.props.fetchSysOrgs()}/>
                </div>
                    </Card.Header>
                    <Card.Body>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="org.id" data={orgs ? orgs : []} columns={columns}
                                filter={filterFactory()}/>
                    </Card.Body>
                </Card>
                
            </div>
        )
    }
}

function mapStateToProps({orgs}) {
    return {
        orgs
    }
}

export default connect(mapStateToProps, {fetchSysOrgs})(SysOrgList);