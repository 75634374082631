import React, {Component} from "react";
import SysSideNav from "./sys_sidenav";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../actions/org_actions";

/**
 * Created by pshivaraman on 3/23/18.
 */

export default function (ComposedComponent) {
    class SysDash extends Component {
        constructor(props) {
            super(props);

            this.state = {
                sideBarOpen: false
            }
        }

        onSideBarToggle(e) {
            e.preventDefault();
            if (this.state.sideBarOpen) {
                this.setState({
                    sideBarOpen: false
                });
            } else {
                this.setState({
                    sideBarOpen: true
                });
            }
        }

        render() {
            const glyph = this.state.sideBarOpen ? "glyphicon-chevron-left" : "glyphicon-chevron-right";
            const wrapperClass = this.state.sideBarOpen ? "toggled" : "";
            return (
                <div id="wrapper" className={wrapperClass}>
                    <SysSideNav/>
                    <button type="button" className="btn btn-primary btn-xs sidebar-toggle"
                            onClick={this.onSideBarToggle.bind(this)}>
                        {/* <i className={`glyphicon ${glyph}`}/> */}
                        <i class="fa fa-bars"></i>
                    </button>
                    <div id="page-content-wrapper">
                        <ComposedComponent {...this.props} />
                    </div>
                </div>
            )
        }
    }

    return connect(null, null)(SysDash);
}