import React from "react";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {CommonUtils} from "../../actions/common_utils";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonToolbar, Card, Col, Form} from "react-bootstrap";
import {FormikFieldDropzone} from "../../components/formik/formik_field_dropzone";
import PropTypes from "prop-types";

class TaskEmailDocUploadComponent extends React.Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.state = {
            showModal: false,
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close = () => {
        this.setState({showModal: false});
    }

    onSubmit = (values) => {
        this.close();
        // expect only one document here
        this.props.onUpload(Array.from(values.documents)[0], values.docType);
    }

    render() {
        const {docTypes} = this.props;
        return (
            <React.Fragment>
                <Button onClick={this.open} variant="success">
                    <span className="fa fa-cloud-upload" aria-hidden="true"/> Upload Document
                </Button>
                <Modal size="lg" show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Document Upload Form</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            documents: "",
                            docType: docTypes[0]
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                documents: Yup.mixed().test(
                                    'test-name',
                                    'There must be a file uploaded',
                                    function (value) {
                                        return value && value.length > 0;
                                    }
                                ).nullable().required('You must upload a document'),
                                docType: Yup.string().required('Document type is required to process the upload')
                            })

                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Upload Document (Can only select one document at a time)</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'documents'}>
                                                <Field
                                                    name="documents"
                                                    component={FormikFieldDropzone}
                                                    multiple={false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId={'docType'}>
                                                <Form.Label>Required Document Type</Form.Label>
                                                <Form.Control required as="select" name={'docType'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.docType && !!errors.docType}
                                                              value={values.docType}
                                                >
                                                    {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(docTypes))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Upload</Button>
                                            <Button variant="danger" onClick={this.close}>Cancel</Button>
                                        </ButtonToolbar>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>);
    }
}

export const TaskEmailDocUpload = connect(null, {})(TaskEmailDocUploadComponent);

TaskEmailDocUpload.propTypes = {
    docTypes: PropTypes.array.isRequired,
    onUpload: PropTypes.func.isRequired
};

