import React, {Component, Fragment} from "react";
import Modal from "react-bootstrap/Modal";
import {Field, reduxForm} from "redux-form";
import {
    FieldRBCountrySelector,
    FieldRBDatePicker,
    FieldRBInput,
    FieldRBMaskedInput, FieldRBSelect
} from "../../../../components/redux_form/field_components_rb";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {connect} from "react-redux";
import {fetchPerson, mergeDuplicateSubject, updateSubjectGeneralDetails} from "../../../../actions/subject_actions";
import {showSuccessGrowl} from "../../../../actions/msg_actions";

class SysSubjectMergeEditModal extends Component {
    constructor(props){
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {

    }

    onSubmit(values){
        this.props.mergeDuplicateSubject(this.props.ssn, values, () => {
            this.props.showSuccessGrowl("Subjects Merged", "Subjects merged successfully!");
            this.close();
        });
    }

    open(){
        const {mergedSubject} = this.props;

        if (!mergedSubject.gender || mergedSubject.gender === ''){
            mergedSubject.gender = 'Male';
        }

        if (!mergedSubject.maritalStatusCode || mergedSubject.maritalStatusCode === ''){
            mergedSubject.maritalStatusCode = 'Single';
        }

        this.props.initialize(mergedSubject);

        this.setState({
            showModal: true
        });
    }

    close(){
        this.setState({
            showModal: false
        });
        this.props.change("personFirstName", null);
        this.props.change("personLastName", null);
        this.props.change("emailAddress", null);
        this.props.change("socialSecurityNumber", null);
        this.props.change("personBirthDate", null);
        this.props.change("cityOfBirth", null);
        this.props.change("stateOfBirth", null);
        this.props.change("countryOfBirth", null);
        this.props.change("gender", null);
        this.props.change("maritalStatusCode", null);
        this.props.onFinish();
    }

    render(){
        const {showModal} = this.state;
        const {handleSubmit} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>Merge</Button>
                <Modal show={showModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Merge Subject Page</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <h3>Update General Details</h3>
                        <Field name="personFirstName" required component={FieldRBInput} horizontal label="First Name"/>
                        <Field name="personLastName" required component={FieldRBInput} horizontal label="Last Name"/>
                        <Field name="emailAddress" component={FieldRBInput} horizontal label="Email Address"/>
                        <Field name="socialSecurityNumber" required component={FieldRBMaskedInput} horizontal mask="*99-99-9999"
                               label="Social Security Number (SSN)"/>
                        <Field name="personBirthDate" required component={FieldRBDatePicker} horizontal label="Date of Birth"/>
                        <Field name="cityOfBirth" component={FieldRBInput} horizontal label="Birth City"/>
                        <Field name="stateOfBirth"  component={FieldRBInput} horizontal label="Birth State"/>
                        <Field name="countryOfBirth" component={FieldRBInput} horizontal label="Birth Country"/>
                        <Field name="gender" label="Gender" component={FieldRBSelect} horizontal
                               options={Array.of('Male', 'Female')}/>
                        <Field name="maritalStatusCode" label="Marital Status" component={FieldRBSelect}
                               horizontal options={Array.of('Single', 'Married')}/>

                        <Modal.Footer>
                            <Button type="button" onClick={this.close}>Cancel</Button>
                            <Button variant="primary" type="submit">Merge</Button>
                        </Modal.Footer>
                    </Form>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};
    // validate
    if (!values.personFirstName) {
        errors.personFirstName = "Enter a Person First Name";
    }
    if (!values.personLastName) {
        errors.personLastName = "Enter a Person Last Name";
    }
    if (!values.personBirthDate) {
        errors.personBirthDate = "Enter a Date of Birth";
    }
    // if (!values.emailAddress) {
    //     errors.emailAddress = "Enter email address";
    // } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(values.emailAddress)){
    //     errors.emailAddress = "Enter a valid email address";
    // }
    // if (!values.countryOfBirth) {
    //     errors.countryOfBirth = "Enter Country of Birth";
    // }
    if (!values.socialSecurityNumber) {
        errors.socialSecurityNumber = "SSN is required";
    } else if (!/^[0-9a-zA-Z][0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(values.socialSecurityNumber)) {
        errors.socialSecurityNumber = "Enter a valid SSN";
    }
    // if the errors is empty all is well
    return errors;
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'SubjectMergeForm',
    initialValues: {
        gender: 'Male',
        maritalStatusCode: 'Single',
        country: 'US',
    }
})(
    connect(null, {mergeDuplicateSubject, showSuccessGrowl})(SysSubjectMergeEditModal)
);