/**
 * Created by pshivaraman on 7/24/18.
 */

import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {connect} from "react-redux";
import Nav from "react-bootstrap/Nav";

class SysSideNav extends React.Component {
    constructor(props) {
        super(props);
    }

    onSideBarSelect(key) {
        switch (key) {
            case 1:
                break;
            case 2:
                break;
            case 4:
                break;
            case 5:
                break;
        }
    }

    render() {
        return (
            <div id="sidebar-wrapper">
                <Nav bsPrefix="sidebar-nav" onSelect={this.onSideBarSelect.bind(this)}>
                    <Nav.Item>
                        <LinkContainer to="/sys/dash" exact>
                            <Nav.Link eventKey={1}><span className="fa fa-home" aria-hidden="true"/> System Dashboard</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                        <LinkContainer to="/sys/dash/org">
                            <Nav.Link eventKey={2}><span className="fa fa-building" aria-hidden="true"/> Organizations</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                        <LinkContainer to="/sys/dash/user">
                            <Nav.Link eventKey={3}><span className="fa fa-users" aria-hidden="true"/> Users</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                        <LinkContainer to="/sys/dash/task">
                            <Nav.Link eventKey={8}><span className="fa fa-tasks" aria-hidden="true"/> Tasks</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                        <LinkContainer to="/sys/dash/subject/duplicateSubjects">
                            <Nav.Link eventKey={5}><span className="fa fa-users" aria-hidden="true" /> Subjects</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                        <LinkContainer to="/sys/dash/signaturePurchase">
                            <Nav.Link eventKey={9}><span className="fa fa-signature" aria-hidden="true"/> Signatures</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                </Nav>
            </div>
        )
    }
}

function mapStateToProps({}) {
    return {}
}


export default connect(mapStateToProps, {})(SysSideNav);