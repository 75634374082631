import React, {Component, Fragment} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {confirmPasswordChange} from "../../actions/user_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import Card from "react-bootstrap/Card";
import {FieldRBInput} from "../../components/redux_form/field_components_rb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class UserPasswordResetComponent extends Component {
    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        this.props.confirmPasswordChange(values, this.props.match.params.emailCode).then(() => {
            this.props.showSuccessGrowl("Password updated successfully");
            this.props.history.push("/misc/closeBrowser");
        }).catch((error) => {
            this.props.showErrorGrowl(error.response.data)
        });
    }

    render() {
        const {handleSubmit, error} = this.props;

        return (
            <Fragment>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <h3>Reset Password</h3>
                    <Card>
                        <Card.Header><Fragment>
                            Enter New Password
                        </Fragment></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={{span: 6, order: 'last'}}>
                                    <Alert variant={'info'}>
                                        <Alert.Heading>Password Criteria</Alert.Heading>
                                        <ul>
                                            <li>At least 8 characters</li>
                                            <li>At least 1 lower case letter</li>
                                            <li>At least 1 upper case letter</li>
                                            <li>At least 1 number</li>
                                            <li>At least 1 of the following (!,@,#,$,%,&,*,?)</li>
                                        </ul>
                                    </Alert>
                                </Col>
                                <Col md={{span: 6, order: 'first'}}>
                                    <Field name="password" component={FieldRBInput} type="password" label="Password"/>
                                    <Field name="passwordConf" component={FieldRBInput} type="password"
                                           label="Confirm Password"/>
                                    <ButtonToolbar className={'pull-right'}>
                                        <Button variant="primary" type="submit">Submit</Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.password) {
        errors.password = "Enter a password";
    } else if (values.password !== values.passwordConf) {
        errors.password = "Passwords must match!";
        errors.passwordConf = "Passwords must match!";
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/.test(values.password)) {
        errors.password = "Password does not meet criteria!";
    }

    return errors;
}

export default reduxForm({
    validate,
    form: "UserPassswordResetComponentForm"
})(
    connect(null, {confirmPasswordChange, showSuccessGrowl, showErrorGrowl})(UserPasswordResetComponent)
);