/**
 * Created by pshivaraman on 6/27/18.
 */

import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import {getDocTypes, getEntityTypes} from "../../../../actions/fieldValue_actions";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {
    FieldRBDatePicker,
    FieldRBDropzone,
    FieldRBInput, FieldRBSelect,
    FieldRBSelectWithKeys,
    FieldRBTextArea
} from "../../../../components/redux_form/field_components_rb";
import {createEmailTask} from "../../../../actions/task_actions";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {getOrgContracts} from "../../../../actions/contract_actions";
import {getActiveOrgPersons, getOrgPersons} from "../../../../actions/subject_actions";
import {getOrgFacilities} from "../../../../actions/facility_actions";
import {MASS_EMAIL_FOR_ALL, MASS_EMAIL_FOR_ALL_ORG_ADMINS} from "../../../../actions/action_constants";

class SysTaskNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availDocTypes: [],
            availEntities: [{
                id: "none",
                name: "Select Entity..."
            }],
            showEntityBox: false
        }
    }

    componentDidMount() {
        getDocTypes((response) => {
            this.setState({
                availSubjectDocTypes: response.data.map((docType) => {
                    return {
                        id: docType.id,
                        name: docType.type
                    }
                })
            });
        });

    }

    onSubmit(values) {
        values.massEmailIssue = true;
        values.entityType = 'ORG';
        values.entityId = sessionStorage.getItem(CUR_ORG);
        values.requiredDocs = [];
        this.props.createEmailTask(values, () => {
            this.props.history.push("/sys/dash/task"); // Ignoring the entity type filter here as all tasks at sys admin task list relate to the generic org
            this.props.showSuccessGrowl("Task Created Successfully!");
        }, (error)=>{
            this.props.showErrorGrowl(error.response.data.message);
        });
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/sys/dash/task">
    //                 Tasks
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 New Task
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {handleSubmit} = this.props;

        return (
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                {/* {this.getBreadCrumb()} */}
                <h3>New Task</h3>
                <Field name="name" required component={FieldRBInput} horizontal label="Name"/>
                <Field name="desc" required label="Description" component={FieldRBTextArea} horizontal/>
                <Field name="dueDate" required component={FieldRBDatePicker} horizontal label="Due Date"/>

                <Field name="broadcastEmailType" component={FieldRBSelect} options={[MASS_EMAIL_FOR_ALL_ORG_ADMINS, MASS_EMAIL_FOR_ALL]} label="Broadcast Type" horizontal/>

                <Field name="userInstructionsDocs" component={FieldRBDropzone} horizontal
                       label="User Instruction Documents"
                       multiple={true}/>
                <ButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Link to="/sys/dash/task" className="btn btn-danger">Cancel</Link>
                </ButtonToolbar>
            </Form>
        )
    }
}

function validate(values) {
    const errors = {};
    // validate
    if (!values.name) {
        errors.name = "Enter a Name";
    }
    if (!values.desc) {
        errors.desc = "Enter a Description";
    }
    if (!values.broadcastEmailType){
        errors.broadcastEmailType = "Please select a Broadcast Type";
    }
    if (!values.dueDate) {
        errors.dueDate = "Enter a Due Date";
    }
    // if the errors is empty all is well
    return errors;
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'SysTaskNewForm',
    initialValues: {
        requiredDocs: "OTHER",
        entityType: "none",
        broadcastEmailType: MASS_EMAIL_FOR_ALL_ORG_ADMINS,
        entityId: sessionStorage.getItem(CUR_ORG),
    }
})(
    connect(null, {createEmailTask, showSuccessGrowl, showErrorGrowl})(SysTaskNew)
);