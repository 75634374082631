/**
 * Created by pshivaraman on 11/11/18.
 */

import React, {Component} from "react";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import {textFilter} from "react-bootstrap-table2-filter";
import filterFactory from "react-bootstrap-table2-filter";
import {fetchUsers, deleteUser} from "../../../../actions/user_actions";
import {connect} from "react-redux";
import CreateUserModal from "../../../../components/user/create_user_modal";
import UserPasswordResetModal from "../../../../components/user/user_password_reset_modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";

class SysUserList extends Component{
    constructor(props) {
        super(props);

        this.onUserCreate = this.onUserCreate.bind(this);
    }

    componentDidMount(){
        this.onUserCreate();
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Users
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    onUserCreate(){
        this.props.fetchUsers();
    }

    onUserDelete = (userId) => {
        this.props.deleteUser(userId, () => {
            this.props.fetchUsers();
            this.props.showSuccessGrowl("User deleted successfully");
        }, (error) => {
            this.props.showErrorGrowl("Unexpected error occurred when deleting the user");
        })

    }
    render() {
        const {users} = this.props;
        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        }
        const columns = [
            {
                dataField: "userName",
                text: "Username",
                sort: true,
                filter: textFilter(),
                headerFormatter: headerFormatter
            },
            {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => (
                    <ButtonToolbar style={{flexWrap: "nowrap"}}>
                        <UserPasswordResetModal user={row}/>
                        <DeleteConfirmationModal entityId={row.id} deleteEntity={`User ${row.userName}`} handleDeleteCallback={this.onUserDelete}/>
                    </ButtonToolbar>

                )
            }
        ];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div className="float-right">
                    <CreateUserModal onCreate={this.onUserCreate} sysAdminUserCreation={true}/>
                </div>
                <h3 className="page_title_left mb-20">Users</h3>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="userName" data={users} columns={columns}
                                filter={filterFactory()}/>
            </div>
        )
    }
}

function mapStateToProps({users}) {
    return {
        users
    };
}

export default connect(mapStateToProps, {fetchUsers, showSuccessGrowl, showErrorGrowl, deleteUser})(SysUserList);