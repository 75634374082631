/**
 * Created by pshivaraman on 9/1/18.
 */

import React, {Component, Fragment} from "react";
import {fetchUsersByRole, updateUserRoleForOrg} from "../../../../actions/user_actions";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";
import {fetchManagedOrgs, fetchOrg, unassignManagingOrg, updateOrgForSigTemplates} from "../../../../actions/org_actions";
import {connect} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {Link} from "react-router-dom";
import ManagedOrgModal from "../../../../components/org/managed_org_modal";
import AssignUserOrgAdminModal from "../../../../components/user/assign_user_orgadmin_modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import {fetchAvailableTemplates} from "../../../../actions/signature_actions";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";
import {Card, Tab} from "react-bootstrap";
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {dumpAllDocumentsOfAnOrg} from '../../../../actions/doc_actions';
import {fetchAvailableStrlngPackages, updatePackagesWithOrgRel} from "../../../../actions/bg_check_actions";
import SigTemplateList from "./sigTemplateList";

class SysOrgDetail extends Component {
    constructor(props) {
        super(props);

        this.fetchUsers = this.fetchUsers.bind(this);
        this.onManagedOrgAssigned = this.onManagedOrgAssigned.bind(this);
        this.onAssignOrgAdmin = this.onAssignOrgAdmin.bind(this);
        this.state = {
            selectedBgPackages : []
        };
    }

    onManagedOrgAssigned() {
        this.props.fetchManagedOrgs(this.props.match.params.id);
    }

    onAssignOrgAdmin() {
        this.fetchUsers();
    }

    getTemplates = async () => {
        let templates = await fetchAvailableTemplates(
            (error) => {
                console.log("Template fetch error", error);
                this.props.showErrorGrowl("Signature Templates Error", "Unable to fetch signature templates")
            }
        );
        this.setState({
            availTemplates: templates.map(template => {
                return {
                    value: template.id,
                    label: template.name
                }
            })
        });
    }

    getStrlngPackages = async () => {
        let strlngPackages = await fetchAvailableStrlngPackages(
            (error) => {
                console.log("Background check Sterling Packages fetch error", error);
                this.props.showErrorGrowl("Background check Sterling Packages Error", "Unable to fetch sterling packages")
            }
        );
        let availStrlngPackages = strlngPackages.map(strlngPackage => {
            let details = '';
            if (strlngPackage.components && strlngPackage.components.length > 0){
                details = ` - (${strlngPackage.components.join(', ')})`;
            }
            return {
                value: strlngPackage.id,
                label: `${strlngPackage.title}${details}`
            }
        })
        this.setState({
            availStrlngPackages,
            availPackagesWithAllData: strlngPackages
        });
    }

    componentDidMount() {
        this.props.fetchOrg(this.props.match.params.id);
        this.props.fetchManagedOrgs(this.props.match.params.id);
        this.fetchUsers();
        this.getTemplates();
        this.getStrlngPackages();
    }

    fetchUsers() {
        this.props.fetchUsersByRole("ORG", this.props.match.params.id, "ORG_ADMIN", true);
    }

    onUnassign(managedOrgId) {
        this.props.unassignManagingOrg(managedOrgId, () => {
            this.props.fetchManagedOrgs(this.props.match.params.id);
        });
    }

    removeOrgAdmin(userId) {
        this.props.updateUserRoleForOrg(userId, this.props.match.params.id, "NONE", () => {
            this.fetchUsers();
        });
    }

    // getBreadCrumb() {
    //     const {org} = this.props;
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/sys/dash/org">
    //                 Organizations
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 {org.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    setChosenTemplatesFromObj = (org) => {
        if (org.signatureTemplates) {
            org.sigTemplates = [];
            for (let template of org.signatureTemplates) {
                org.sigTemplates.push(template.id);
            } 
            let sigTemplates = org.sigTemplates;
            let { availTemplates } = this.state;
            let availTemplatelist = availTemplates.filter(objA => !sigTemplates.includes(objA.value));
            availTemplatelist = availTemplatelist.map(r => {
                return {
                    name: r.label,
                    id: r.value
                }
            }) 
            org.availTemplatelist = availTemplatelist;
        }
    }

    setChosenPackagesFromObj = (org, availPackages) => {
        let {selectedBgPackages} = this.state;
        if (org) {
            if (availPackages && availPackages.length > 0) {
                console.log('Avail packages are ', availPackages)
                for (let strlngPackage of availPackages) {
                    if (strlngPackage.orgIds && strlngPackage.orgIds.includes(org.id)) {
                        selectedBgPackages.push(strlngPackage.id);
                    }
                }
                console.log('Selected packages are ', selectedBgPackages)
            }
        }
    }

    handleDump = async () => {
        const {org} = this.props;
        dumpAllDocumentsOfAnOrg(org.id);
    }
    onPackagesSelected = async (values, actions) => {
        const {org} = this.props;
        let selectedIds = values.strlngPackages;
        let {availPackagesWithAllData} = this.state;
        for (let eachPackage of availPackagesWithAllData){
            if (selectedIds.includes(eachPackage.id)){
                // if org id does not exist add
                if (!eachPackage.orgIds){
                    eachPackage.orgIds = [];
                }
                if (!eachPackage.orgIds.includes(org.id)){
                    eachPackage.orgIds.push(org.id);
                }
            } else {
                // if org exists then remove
                if (eachPackage.orgIds){
                    let index = eachPackage.orgIds.indexOf(org.id);
                    if (index > -1){
                        eachPackage.orgIds.splice(index, 1);
                    }
                }
            }
        }
        actions.setSubmitting(false);
        console.log('Before updating', availPackagesWithAllData);
        try {
            await updatePackagesWithOrgRel(availPackagesWithAllData);
            this.props.showSuccessGrowl("Organization Updated Successfully", "Organization's background check packages updated");
            this.props.history.push("/sys/dash/org");
        } catch (error){
            this.props.showErrorGrowl("Organization Update Failed", "Organization's background check packages update failed");
            console.log(error);
            this.props.history.push("/sys/dash/org");
        }

    } 

    

    render() {
        const {orgs, users, org} = this.props;
        let {availTemplates, availStrlngPackages, selectedBgPackages, availPackagesWithAllData} = this.state;
        if (!availTemplates) {
            return <div>Loading...</div>;
        }

        // Get the selected org's template available
        this.setChosenTemplatesFromObj(org);
        // Get the selected org's packages available
        this.setChosenPackagesFromObj(org, availPackagesWithAllData);

        const orgColumns = [
            {
                dataField: "name",
                text: "Name",
                sort: true
            },
            {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => <Button onClick={() => this.onUnassign(row.org.id)}>Unassign</Button>
            }
        ];

        const userColumns = [
            {
                dataField: "userName",
                text: "Username",
                formatter: (cell, row) => <Link to={`/sys/dash/user/${row.id}`}>{cell}</Link>,
                sort: true,
                // filter: textFilter()
            },

            {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => <Button onClick={() => this.removeOrgAdmin(row.id)}>Unassign</Button>
            }
        ];

        const getUsersComponent = () => {
            if (users){
                return (
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="userName" data={users} columns={userColumns}
                                    filter={filterFactory()}/>
                )
            }
            return <div>Loading...</div>;
        };

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <CollapsibleCard header="Organization Administrators" defaultOpen>
                    {getUsersComponent()}
                    <hr/>
                    <AssignUserOrgAdminModal managingOrgId={this.props.match.params.id}
                                             onFinish={this.onAssignOrgAdmin}/>
                </CollapsibleCard>
                <CollapsibleCard header="Managed Organizations">
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={orgs} columns={orgColumns} filter={filterFactory()}/>
                    <hr/>
                    <ManagedOrgModal managingOrgId={this.props.match.params.id} onFinish={this.onManagedOrgAssigned}/>
                </CollapsibleCard>
                <CollapsibleCard header={'Signature Management'}> 
                    <SigTemplateList id={this.props.match.params.id} orgId={org.id}/>
                </CollapsibleCard>
                <CollapsibleCard header={'Background Check Package Management'}>
                    <Formik
                        initialValues={{
                            "strlngPackages": selectedBgPackages,
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onPackagesSelected}
                        validationSchema={
                            Yup.object().shape({})
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Update Sterling Package Assigned</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Assign Packages</Form.Label>
                                                <Field
                                                    id="strlngPackages"
                                                    name="strlngPackages"
                                                    component={FormikFieldDualListBox}
                                                    options={availStrlngPackages}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.strlngPackages}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>Update</Button>
                                            <Link to="/sys/dash/org" className="btn btn-danger">Cancel</Link>
                                        </ButtonToolbar>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </CollapsibleCard>
                <CollapsibleCard header={'Miscellaneous'}>
                    <div className="float-right">
                        <ButtonToolbar>
                            <DeleteConfirmationModal buttonText={'Dump All Documents'} actionText={'download all documents for'}
                                                     deleteEntity={'organization'}
                                                     buttonType={'primary'}
                                                     handleDeleteCallback={this.handleDump}/>
                        </ButtonToolbar>
                    </div>
                </CollapsibleCard>
            </div>
        )
    }
}

function mapStateToProps({org, managed_orgs, users}) {
    return {
        org,
        orgs: managed_orgs,
        users
    }
}

export default connect(mapStateToProps, {
    fetchManagedOrgs,
    fetchUsersByRole,
    updateOrgForSigTemplates,
    fetchOrg,
    showSuccessGrowl,
    showErrorGrowl,
    unassignManagingOrg,
    updateUserRoleForOrg
})(SysOrgDetail);