import React, { Component, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import DeleteConfirmationModal from "../../../../components/delete_confirm_modal"
import { CommonUtils } from "../../../../actions/common_utils";
import { getDocTypes } from "../../../../actions/fieldValue_actions";
import { FieldRBSelectWithKeys } from "../../../../components/redux_form/field_components_rb";
import { showErrorGrowl, showSuccessGrowl } from "../../../../actions/msg_actions";
import { fetchAvailableTemplates } from "../../../../actions/signature_actions";
import { fetchOrg } from "../../../../actions/org_actions";
class TemplateModelComp extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);

        this.state = {
            showModal: false,
            docTypes: [] 
           
        };
    }
    componentDidMount() { 
        getDocTypes((response) => {
            const _docTypes = response.data.map((docType) => {
                return {
                    id: docType.id,
                    name: docType.type,
                };
            });
            this.setState({
                docTypes: _docTypes,
            });
        }); 
    }
    onSubmit = (values) => {
        this.setState({
            showModal: false,
        }); 
        this.props.handleSubmit(values)
    }
    open = () => {
        this.setState({
            showModal: true
        });
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }
 
    render() { 
        const {showModal,docTypes } = this.state;
        const { tempType,selectedDoctype,availTemplates} = this.props; 
        return (
            <Fragment>   
                {tempType === "Edit" ? <Button variant={'link'} className="close" onClick={this.open}>
                    <span aria-hidden="true" title={"Edit Template"} style={{ padding: 10 }} aria-label="Edit">
                        <i className="fa fa-square-pen"></i>
                    </span>
                </Button> :
                <div className="align-right mb-3">
                        <Button variant="primary" onClick={this.open} disabled={!availTemplates.length>0}>
                            Add Template
                        </Button>
                </div>
                   
                }
                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{tempType == "New" ? "Add Template" : "Edit Template"}</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            "id": availTemplates.length>0 && availTemplates[0].id,
                            "documentType": tempType === "Edit" && selectedDoctype ?  selectedDoctype :  docTypes.length>0 && docTypes[0].id
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit} 
                    >
                        {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Template</Form.Label>
                                            <Form.Control required as="select" name="id" onChange={handleChange}
                                                onBlur={handleBlur} component={FieldRBSelectWithKeys}
                                                isInvalid={touched.id && !!errors.id}
                                                disabled={tempType === "Edit"} 
                                            >
                                                {CommonUtils.getOptionsForSelect(availTemplates)}
                                            </Form.Control> 
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control required as="select" name="documentType" id="documentType" onChange={handleChange}
                                                onBlur={handleBlur} component={FieldRBSelectWithKeys}
                                                isInvalid={touched.documentType && !!errors.documentType}
                                                 value={values.documentType}
                                            >
                                                {CommonUtils.getOptionsForSelect(docTypes)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.documentType}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>{tempType == "New"?"Add":"Update"}</Button>
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Signature Template"
                                            handleDeleteCallback={this.close} />  
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const TemplateModel = connect(null, {
    showSuccessGrowl,
    showErrorGrowl
})(TemplateModelComp);

