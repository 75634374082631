import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {customFilter, textFilter} from "react-bootstrap-table2-filter";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchIssuesWithEntityForOrg} from "../../../../actions/task_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {Picky} from "react-picky";
import Alert from "react-bootstrap/Alert";

class SysTaskList extends Component {
    constructor(props) {
        super(props);

        this.onTableChange = this.onTableChange.bind(this);

        this.state = {
            selEntityTypes: [{value: "ORG", label: "Organization"}],
            selStatus: [{value: "ACTIVE", label: "Active"}]
        }
    }

    componentDidMount() {
        this.setState({
            selEntityTypes: [{value: "ORG", label: "Organization"}]
        });
        this.props.fetchIssuesWithEntityForOrg(sessionStorage.getItem(CUR_ORG));
    }

    onTableChange(type, newState) {
        switch (type) {
            case "filter":
                const filters = newState.filters;
                let issueName = "";
                let entityTypes = ["ORG"];
                let entityName = "";
                let issueStatus = ["ACTIVE"];
                if (filters.issueName) {
                    issueName = filters.issueName.filterVal;
                }
                if (filters.entityName) {
                    entityName = filters.entityName.filterVal;
                }
                if (filters.entityType) {
                    entityTypes = filters.entityType.filterVal.map((val) => val.value);
                }
                if (filters.active) {
                    issueStatus = filters.active.filterVal.map((val) => val.value);
                }
                this.props.fetchIssuesWithEntityForOrg(sessionStorage.getItem(CUR_ORG), issueName, entityName, entityTypes, issueStatus);
                break;
        }
    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'issueName',
            text: 'Name',
            formatter: (cell, row) => <Link to={`/sys/dash/task/${row.issueId}`}>{cell}</Link>,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'entityType',
            text: 'Entity Type',
            formatter: (cell, row) => <Link to={`/sys/dash/task/${row.issueId}`}>{ cell && cell.displayName}</Link>,
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'entityName',
            text: 'Entity Name',
            formatter: (cell, row) => <Link to={`/sys/dash/task/${row.issueId}`}>{cell}</Link>,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'active',
            text: 'Status',
            formatter: (cell, row) => <Link to={`/sys/dash/task/${row.issueId}`}>{cell ? "Active" : "Completed"}</Link>,
            sort: true,
            filter: customFilter(),
            filterRenderer: (onFilter, column) => {
                const options = [
                    {value: "ACTIVE", label: "Active"},
                    {value: "COMPLETED", label: "Completed"}
                ]

                const handleSelect = (values) => {
                    if (values.length === 0){
                        values = [{value: "ACTIVE", label: "Active"}]
                    }
                    this.setState({selStatus: values});
                    onFilter(values);
                }

                return <Picky placeholder="Select Status" value={this.state.selStatus} multiple={true}
                              options={options} onChange={handleSelect} valueKey="value" labelKey="label"
                              numberDisplayed={1}/>
            },
            headerFormatter: headerFormatter
        }];
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/sys/dash">
    //                 System Dashboard
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Tasks
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        let {tasks} = this.props;

        if (!tasks) {
            return <div>Loading...</div>
        }

        const remoteSettings = {
            filter: true,
            pagination: false,
            sort: false,
            cellEdit: false
        };

        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                <div className="float-right">
                    <ButtonToolbar>
                        <Link className="btn btn-primary" to="/sys/dash/task/new">New Task</Link>
                    </ButtonToolbar>
                </div>
                <h3 className="page_title_left mb-20">Tasks</h3>
                {(tasks.length === 0) && <Alert variant="primary">No Tasks</Alert>}
                {(tasks.length > 0) &&
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={tasks}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                onTableChange={this.onTableChange}/>}
            </Fragment>
        )
    }
}

function mapStateToProps({tasks}) {
    return {
        tasks
    }
}

export default connect(mapStateToProps, {fetchIssuesWithEntityForOrg})(SysTaskList);